import { FC } from 'react';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';

import { APP_ROUTES } from '@lib/route/constants';
import { useTenantProps } from '@lib/tenants/TenantPropsContext';
import { SxStyles } from '@lib/theme/sxTheme';
import { Box } from '@mui/system';
import { DynamicImage } from '@ui/gallery/DynamicImage';
import { StaticImage } from '@ui/gallery/StaticImage';

export const TenantLogo: FC = () => {
  const { t } = useTranslation();
  const { mediaAssets } = useTenantProps();
  const router = useRouter();
  const { tenant } = useTenantProps();

  return (
    <Box sx={styles.getValue('clickableIcon')} onClick={goToHome}>
      {mediaAssets?.logo.url ? (
        <DynamicImage
          src={mediaAssets?.logo.url}
          alt={t('common:alt.logo')}
          width={getLogoWidth()}
          height={getLogoHeight()}
          objectFit="contain"
        />
      ) : (
        <StaticImage
          src={'/tenants/base/icons/tata-logo.png'}
          alt={t('common:alt.logo')}
          width={getLogoWidth()}
          height={getLogoHeight()}
          objectFit="contain"
        />
      )}
    </Box>
  );

  function getLogoWidth(): number {
    const maxLogoWidth = 200;

    if (
      mediaAssets?.logo?.dimensions?.width &&
      mediaAssets?.logo?.dimensions?.width > maxLogoWidth
    ) {
      return maxLogoWidth;
    }

    return mediaAssets?.logo.dimensions.width ?? maxLogoWidth;
  }

  function getLogoHeight(): number {
    const maxLogoHeight = 60;

    if (
      mediaAssets?.logo?.dimensions?.height &&
      mediaAssets?.logo?.dimensions?.height > maxLogoHeight
    ) {
      return maxLogoHeight;
    }

    return mediaAssets?.logo.dimensions.height ?? maxLogoHeight;
  }

  function goToHome(): void {
    router.push(tenant.config?.externalHomePageUrl ?? APP_ROUTES.HOME.path);
  }
};

const styles = new SxStyles({
  clickableIcon: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    pt: 0.5,
    pb: 0.5,
  },
});
